module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nozem audio","short_name":"Nozem","start_url":"/","background_color":"#333333","theme_color":"#333333","display":"standalone","icon":"./static/images/nozem-white-N.png","orientation":"portrait-primary","lang":"en-US"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
