import React from "react";
import { Link } from "gatsby";

export default props => {
    return (
        <Link title="Nozem" to="/" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 833">
                <path d="M564.161 2.547c-.871 1.911-1.161 63.62-1.161 247 0 134.449-.38 244.453-.845 244.453-.783 0-19.612-16.17-37.155-31.908-4.125-3.701-11.325-10.057-16-14.126-4.675-4.069-8.725-7.645-9-7.947-.275-.301-3.65-3.241-7.5-6.533-3.85-3.293-7.225-6.237-7.5-6.545-.275-.307-10.175-9.065-22-19.461-11.825-10.396-26.45-23.265-32.5-28.598-6.05-5.333-19.55-17.231-30-26.441-10.45-9.209-22.15-19.53-26-22.934-3.85-3.405-15.325-13.499-25.5-22.432-10.175-8.933-21.65-19.041-25.5-22.461-6.691-5.946-117.213-103.252-162.551-143.114-38.436-33.794-45.389-39.918-48.949-43.109-1.925-1.726-11.6-10.22-21.5-18.875S69.125 60.748 65 57.043c-4.125-3.704-15.375-13.634-25-22.065-9.625-8.431-21.434-18.85-26.241-23.153C8.951 7.521 4.338 4 3.509 4 2.173 4 2 16.003 2 108.382v104.381l11.75 10.442c6.463 5.743 22.567 20.084 35.787 31.868 13.221 11.785 34.146 30.416 46.5 41.402 12.355 10.986 29.213 26.017 37.463 33.403 8.25 7.385 16.35 14.603 18 16.041 5.268 4.589 30.981 27.524 31.5 28.097.275.303 14.45 12.95 31.5 28.103 17.05 15.154 35.5 31.575 41 36.492 5.5 4.917 26.425 23.545 46.5 41.396 20.075 17.85 36.725 32.699 37 32.998.275.298 10.625 9.547 23 20.553 71.518 63.605 88.897 79.081 92.5 82.37 2.2 2.009 26.5 23.648 54 48.087 27.5 24.44 50.225 44.682 50.5 44.984.275.301 10.85 9.749 23.5 20.994 29.793 26.485 30.042 26.707 35.962 32.077 2.771 2.513 15.388 13.751 28.038 24.973s23.225 20.64 23.5 20.93c.518.546 15.259 13.687 22.79 20.318 2.36 2.077 4.723 3.51 5.25 3.184 1.382-.854 1.306-826.96-.075-829.541C696.957.052 695.161 0 631.125 0h-65.804l-1.16 2.547M2 685.5V833h137V663.155l-21.25-19.769c-11.687-10.873-24.85-23.172-29.25-27.331-7.207-6.813-21.236-19.885-43.988-40.986-4.406-4.087-11.162-10.417-15.012-14.067C12.659 545.036 4.67 538 3.382 538 2.212 538 2 560.655 2 685.5" fill="white" fill-rule="evenodd" xmlns="http://www.w3.org/2000/svg"/>
            </svg>
        </Link>
    );
};
